<template>
  <div class="mainn">
    <ReturnProcessMaterial @pageNumber="handlePage" />
    <ReturnProcessToStockMaterials :pageOfMaterial="pageOfMaterial" />
  </div>
</template>

<script>
import ReturnProcessToStockMaterials from '@/components/materialsNominvative/ReturnProcessToStockMaterials.vue'
import ReturnProcessMaterial from '@/components/materialsNominvative/ReturnProcessMaterial.vue'

export default {
  components: {
    ReturnProcessMaterial,
    ReturnProcessToStockMaterials,
  },
  data() {
    return {
      pageOfMaterial: '',
    };
  },
  methods: {
    handlePage(page) {
      this.pageOfMaterial = page;
    },
  },
}


</script>
  <style>
  .mainn{
    padding: 75px 15px 15px 120px;
      display: flex;
      gap: 30px;
  }

  </style>
