<template>
  <div
    class="mats-listing__wrapper"
    style="width: 30%"
  >
    <div style="width: 100%; padding-top: 16px; margin-top: 0px;">
      <table
        class="team_table"
      >
        <thead>
          <tr>
            <th>
              {{ $t('ArticleName') }}
            </th>
            <th>
              {{ $t('Team') }}
            </th>
            <th>
              {{ $t("Edit") }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="item in getArticleOrders"
            :key="item.articleNumber"
          >
            <td>
              {{ item.articleNumber }}
            </td>
            <td>
              {{ item.teamName }}
            </td>
            <td
              v-b-toggle.return-toggle-materials
              @click="getEditOrderItemm(item.orderItemId); getEditOrderItemMaterialVariant(item.orderItemId); articleForTitle(item.articleNumber)"
            >
              <button
                class="button"
              >
                {{ $t('Edit') }}
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <ReturnToggleMaterials
      :selected-order-item="selectedOrderItem"
      @onCancelToggle="onCancelToggle"
      @editMaterialsOrder="edittOrder"
    />
    <div
      v-if="isSidebarOpen"
      class="backdrop"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment'
import ReturnToggleMaterials from './ReturnToggleMaterials.vue';



export default {
  components: {
    ReturnToggleMaterials,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: {
    pageOfMaterial: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      page: 1,
      pageSize: 15,
      articleNumber: null,
      isSidebarOpen: false,
      selectedOrderItem: null,
    }
  },
  computed: {
    ...mapGetters(['getArticleOrders']),
    allSelected() {
      return (
        this.getArticleOrders.length > 0
          && this.selectedArticles.length === this.getArticleOrders.length
      );
    },
  },
  watch: {

  },
  mounted() {

  },
  methods: {
    ...mapActions(['get_edit_OrderItem', 'resetReadOrderItems', 'loadDeliveredOrders', 'returnMaterialOrder', 'accountDetails', 'getMaterialVariantsToMDCPagination', 'edit_OrderItem_MaterialVariants']),
    edittOrder(value) {
      this.returnMaterialOrder({
        object: value,
        successCallback: () => {
          this.resetReadOrderItems()
          this.loadDeliveredOrders({
            orderNumber: null,
            year: moment().format('YYYY'),
            clientId: null,
            pageNumber: this.pageOfMaterial,
            pageSize: 15,
            salesType: 'Material',
          })
        },
      })
    },
    onCancelToggle() {
      this.isSidebarOpen = false
    },
    articleForTitle(value) {
      this.articleNumber = value
    },
    getEditOrderItemMaterialVariant(value) {
      this.edit_OrderItem_MaterialVariants(value)
    },
    getEditOrderItemm(value) {
      this.selectedOrderItem = value
      this.isSidebarOpen = true
      this.get_edit_OrderItem(value)
      this.getMaterialVariantsToMDCPagination({
        pageNumber: 1,
        pageSize: 15,
        measurement: 'Meter',
      })
      this.getMaterialVariantsToMDCPagination({
        pageNumber: 1,
        pageSize: 15,
        measurement: 'Part',
      })
      this.accountDetails({
        isActive: true,
      })
    },
  },
}
</script>

  <style scoped lang="scss">

  .team_table td {
    padding: 12px 9px !important;
  }

  .team_table th {
    // text-align: center;
  }

  </style>
