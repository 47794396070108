<template>
  <div
    class="mats-listing__wrapper"
    style=""
  >

    <div class="filtering">
      <div class="search-toggle">

        <p>{{ $t('SearchBy') }}</p>
        <div>
          <p
            :class="{ active2: !toggleSarch }"
            @click="showCustomerName()"
          >
            {{ $t('CustomerName') }}
          </p>
          <p
            :class="{ active2: toggleSarch }"
            @click="showOrderNumber()"
          >
            {{ $t('OrderNumber') }}
          </p>
        </div>
      </div>
      <div v-if="show == true">
        <div>
          <div class="black">
            <b-icon-search
              v-if="searchCN == ''"
              class="search1"
              style="position: absolute; left: 5px; top: 12px"
            />
            <b-icon-x-circle
              v-else-if="searchCN != '' && !noSearch"
              class="search1"
              style="position: absolute; right: 20px; top: 12px; cursor: pointer"
              @click="searchCN = ''"
            />
            <input
              v-model="searchCN"
              type="text"
              autocomplete="off"
              name="search-box"
              :placeholder="$t('CustomerName')"
              :style="getFirstNames.length > 0 && noSearchTwo ? 'border-radius: 8px 8px 0px 0px' : ''"
              @keyup="inputChanged1"
              @keydown.down="onArrowDown1"
              @keydown.up="onArrowUp1"
            >
          </div>
          <div
            v-if="getFirstNames.length > 0 && noSearchTwo"
            ref="scrollContainer"
            class="dropdrop"
            style="
                display: inline-block;
                overflow: auto;
                position: absolute;
                background: white;
                width: 229px;
                box-shadow: none;
              "
            :style="getFirstNames.length > 7 ? 'height: 210px' : 'height: auto'"
          >
            <div
              v-for="(result, index) in getFirstNames"
              ref="options2"
              :key="index"
              :class="{ 'is-active': index === arrowCounter }"
              style="cursor: pointer"
              class="search-item"
              @click="searchByCn(result ); noSearchTwo = false"
            >
              <p style="margin: 0">
                {{ result.nameToDisplay }}
              </p>
            </div>
            <div
              v-if="getFirstNames.length == 0"
              class="search-item"
            >
              <p>{{ $t('NoResult') }}</p>
            </div>
          </div>
        </div>
      </div>
      <div v-if="show == false">
        <div class="black">
          <b-icon-search
            v-if="searchOrder == ''"
            class="search1"
            style=" position: absolute; left: 5px; top: 13px"
          />
          <b-icon-x-circle
            v-else-if="searchOrder != '' && !noSearch"
            class="search1"
            style="position: absolute; margin-top: -6px; right: 20px; top: 17px; cursor: pointer"
            @click="searchOrder = ''"
          />
          <input
            v-model="searchOrder"
            type="text"
            autocomplete="off"
            name="search-box"
            :placeholder="$t('OrderNumber')"
            :style="getSearchInNewOrders.length > 0 && noSearch ? 'border-radius: 8px 8px 8px 8px' : ''"
            @keyup="inputChanged"
            @keydown.down="onArrowDown"
            @keydown.up="onArrowUp"
          >
        </div>
        <div
          v-if="getSearchInNewOrders.length > 0 && noSearch"
          ref="scrollContainer"
          class="dropdrop"
          :style="getSearchInNewOrders.length > 7 ? 'height: 210px' : 'height: auto'"
        >
          <div
            v-for="(result, index) in getSearchInNewOrders"
            :key="index"
            ref="options"
            style="cursor: pointer"
            class="search-item"
            :class="{ 'is-active': index === arrowCounter }"
            @click="searchByOn(result);"
          >
            <p style="margin: 0">
              {{ result }}
            </p>
          </div>
          <!-- <div
              v-if="getFilteredOrdersbyON.length == 0"
              class="search-item"
            >
              <p>Sorry. No Results.</p>
            </div> -->
        </div>
      </div>
    </div>

    <div style="width: 100%; padding-top: 16px; margin-top: 0px;">
      <table
        class="team_table"
      >
        <thead>
          <tr>
            <th>
              {{ $t("No") }}
            </th>
            <th>
              {{ $t('orderNumber') }}
            </th>
            <th>
              {{ $t('OrderType') }}
            </th>
            <th>
              {{ $t('CustomerName') }}
            </th>
            <th>
              {{ $t('created') }}
            </th>
            <th>
              {{ $t('SalesPerson') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item,index) in getDeliveredOrders"
            :key="index"
            class="hover-row"
            :style="classNameCh === item.orderNumber ? 'background-color: rgba(255, 39, 79, 0.1)' : ''"
            @click="matProps(item)"
          >
            <td>
              {{ getSeliveredOrdersTotalItems - (page - 1) * pageSize - index }}.
            </td>
            <td>{{ item.orderNumber }}</td>
            <td>{{ $t(item.orderType) }}</td>
            <td>{{ item.customerName }}</td>
            <td>{{ convertUTCDateToLoacalDate(item.created) }}</td>
            <td>{{ item.salesPersonFullName }}</td>
          </tr>
        </tbody>
      </table>
      <!-- <table
          class="team_table"
        >
          <thead>
            <tr>
              <th
                v-for="(item,index) in getAdminOrders[0]"
                :key="index"
              >
                <p v-if="index !== 'clientId'">
                  {{ $t(index) }}
                </p>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item,index) in getAdminOrders"
              :key="index"
              class="hover-row"
              :style="classNameCh === item.orderNumber ? 'background-color: rgba(255, 39, 79, 0.1)' : ''"
              @click="matProps(item)"
            >
              <td
                v-for="(value, key) in item"
                :key="key"
              >
                <p v-if="key !== 'clientId'">
                  {{ value }}
                </p>
              </td>
            </tr>
          </tbody>
        </table> -->
      <div>
        <b-pagination
          v-if="getSeliveredOrdersTotalItems > 15"
          v-model="page"
          :total-rows="getSeliveredOrdersTotalItems"
          :per-page="pageSize"
          first-number
          last-number
          align="fill"
          size="md"
          prev-class="prev-item"
          next-class="next-item"
          class="mt-1 mb-0"
        >
          <template #prev-text>
            <span class="previousOrNext"><b-icon-arrow-left />{{ $t('Previous') }}</span>
          </template>
          <template #next-text>
            <span class="previousOrNext">{{ $t('Next') }}<b-icon-arrow-right /></span>
          </template>
        </b-pagination>
      </div>
    </div>
  </div></template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment'



export default {
  components: {

  },
  // eslint-disable-next-line vue/require-prop-types
  props: [],
  data() {
    return {
      toggleSarch: false,
      noSearch: false,
      noSearchTwo: false,
      show: true,
      searchCN: '',
      searchOrder: '',
      page: 1,
      pageSize: 15,
      classNameCh: null,
      arrowCounter: 0,
      enterPressed: false,
      orderState: 'Delivered',
      year: moment().format('YYYY'),
      salesUserId: '',
      filterOrderState: 'Delivered',
    }
  },
  computed: {
    ...mapGetters(['getDeliveredOrders', 'getAdminOrders', 'getTotalItemsForOrders',
      'getFirstNames', 'getSearchInNewOrders', 'getSeliveredOrdersTotalItems']),
  },
  watch: {
    page(value) {
      this.loadDeliveredOrders({
        orderNumber: null,
        year: this.year,
        clientId: null,
        pageNumber: value,
        pageSize: this.pageSize,
        salesType: 'Material',
      })
    },
    searchOrder(value) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (value == '') {
          this.removeLists()
          this.loadDeliveredOrders({
            orderNumber: null,
            year: this.year,
            clientId: null,
            pageNumber: this.page,
            pageSize: this.pageSize,
            salesType: 'Material',
          });
        } else if (this.enterPressed) {
          // eslint-disable-next-line no-useless-return
          return;
        } else {
          this.searchInNewOrders({ orderNumber: value, filterOrderState: this.filterOrderState });
          this.noSearch = true
        }
      }, 500);
    },
    searchCN(value) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (value == '') {
          this.clientId = null
          this.noSearchTwo = false
          // this.fshijCN();
          this.loadDeliveredOrders({
            orderNumber: null,
            year: this.year,
            clientId: null,
            pageNumber: this.page,
            pageSize: this.pageSize,
            salesType: 'Material',
          });
        } else if (this.enterPressed) {
          // eslint-disable-next-line no-useless-return
          return
        } else {
          this.firstNames(value);
          this.noSearchTwo = true
        }
      }, 500);
    },
  },
  mounted() {
    this.loadDeliveredOrders({
      orderNumber: null,
      year: this.year,
      clientId: null,
      pageNumber: this.page,
      pageSize: this.pageSize,
      salesType: 'Material',
    })
  },
  methods: {
    ...mapActions(['loadDeliveredOrders', 'readOrderItems', 'loadCreatedOreders', 'firstNames', 'searchInNewOrders']),
    matProps(value) {
      this.classNameCh = value.orderNumber
      this.$emit('pageNumber', this.page);
      console.log('Sales person', value.salesUserId)
      this.readOrderItems({
        orderNumber: value.orderNumber,
        orderItemState: null,
      })
    },
    showCustomerName() {
      this.toggleSarch = false;
      this.searchCN = '';
      this.show = true
      this.loadDeliveredOrders({
        orderNumber: null,
        year: this.year,
        clientId: null,
        pageNumber: this.page,
        pageSize: this.pageSize,
        salesType: 'Material',
      })
    },
    showOrderNumber() {
      this.toggleSarch = true;
      this.searchOrder = '';
      this.show = false;
      this.loadDeliveredOrders({
        orderNumber: null,
        year: this.year,
        clientId: null,
        pageNumber: this.page,
        pageSize: this.pageSize,
        salesType: 'Material',
      })
    },
    async searchByOn(value) {
      this.searchOrder = value
      this.noSearch = false
      await this.loadDeliveredOrders({
        year: this.year,
        clientId: null,
        pageNumber: this.page,
        pageSize: this.pageSize,
        orderNumber: this.searchOrder,
        salesType: 'Material',
      })
      this.noSearch = false
      // router.push({ name: 'Orders Management', query: { orderNumber: value } });
      this.noSearch = false
    },
    async searchByCn(value) {
      this.searchCN = value.nameToDisplay
      this.clientId = value.clientId
      this.noSearchTwo = false
      await this.loadDeliveredOrders({
        orderNumber: null,
        year: this.year,
        pageNumber: this.page,
        pageSize: this.pageSize,
        clientId: value.clientId,
        salesType: 'Material',
      })
      this.noSearchTwo = false
      // router.push({ name: 'Orders Management', query: { clientId: value.nameToDisplay } });
    },
    inputChanged(event) {
      if (event.code == 'ArrowUp' || event.code == 'ArrowDown') { return; }
      // this.getFilteredActiveUsers = [];

      // eslint-disable-next-line no-useless-return
      if (event.code == 'Enter') {
        this.enterPressed = true
        this.searchByOn(this.getSearchInNewOrders[this.arrowCounter])
        this.removeLists()
        this.arrowCounter = 0
        setTimeout(() => {
          this.enterPressed = false
        }, 1000)
        // eslint-disable-next-line no-useless-return
        return;
      }
    },
    fixScrolling() {
      const liH = this.$refs.options[this.arrowCounter].clientHeight;
      console.log(liH)
      this.$refs.scrollContainer.scrollTop = liH * this.arrowCounter;
    },
    onArrowDown1(ev) {
      ev.preventDefault()
      if (this.arrowCounter < this.getFirstNames.length - 1) {
        this.arrowCounter += 1;
        this.fixScrolling();
      }
    },
    onArrowUp1(ev) {
      ev.preventDefault()
      if (this.arrowCounter > 0) {
        this.arrowCounter -= 1;
        this.fixScrolling()
      }
    },
    inputChanged1(event) {
      if (event.code == 'ArrowUp' || event.code == 'ArrowDown') { return; }
      // this.getFilteredOrdersbyON = [];

      // eslint-disable-next-line no-useless-return
      if (event.code == 'Enter') {
        this.enterPressed = true
        this.searchByCn(this.getFirstNames[this.arrowCounter])
        this.removeLists()
        this.arrowCounter = 0
        setTimeout(() => {
          this.enterPressed = false
        }, 1000)
        // eslint-disable-next-line no-useless-return
        return;
      }
    },
    onArrowDown(ev) {
      ev.preventDefault()
      if (this.arrowCounter < this.getSearchInNewOrders.length - 1) {
        this.arrowCounter += 1;
        this.fixScrolling();
      }
    },

    onArrowUp(ev) {
      ev.preventDefault()
      if (this.arrowCounter > 0) {
        this.arrowCounter -= 1;
        this.fixScrolling()
      }
    },
    removeLists() {
      this.noSearch = false;
      this.resetSearchInNewOrders();
    },
    convertUTCDateToLoacalDate(date) {
      const local = moment.utc(date).local().format('DD-MMM-YYYY h:mm A')
      return local
    },
  },
}
</script>

  <style scoped lang="scss">

  .team_table td {
    padding: 12px 9px !important;
  }

  .team_table th {
    // text-align: center;
  }

  .hover-row:hover {
      background-color: rgba(255, 39, 79, 0.1);
  }
  .dropdrop {
    display: inline-block;
    overflow: auto;
    position: absolute;
    background: white;
    width: 227px;
    box-shadow: none;
  }

  input[name="search-box"] {
    display: block;
    max-width: 500px;
    width: 95%;
    padding: 0.5rem 1rem;
    padding-left: 25px;
  }
  .search-item {
    padding: 5px;
    max-width: 500px;
    width: 100%;
    font-weight: 400;
    color: #82868c;
    font-size: 1rem;
    border-bottom: 1px solid #e8e8e8;
  }
  .search-item:last-child {
    border-bottom: none;
  }
  .search-item:hover {
    background: #82868c;
    color: white;
  }
  .search-item hr {
    color: lightgray;
    border-top: none;
    margin: -1.3rem 0 0.5rem 0;
  }
  .is-active {
    background-color: #dedede;
  }

  .editButton {
    height: 30px;
  }
  .active2 {
    font-weight: bold;
    color: $base-color;
  }
  .active2{
    color: $base-color;
    font-weight: bold;
  }
  .hover-row:hover {
      background-color: rgba(255, 39, 79, 0.1);
  }
  .mats-listing__wrapper {
    .mats-listing{
      margin-top: 0
    }
  }
    .button {
      margin-bottom: 15px!important;
    }
    .mat {
      display: flex;
      justify-content: space-between;
      align-content: center;
      &.active {
        background-color: #e8e8e8;
      }
      div {
        display: flex;
        justify-content: space-between;
        align-content: center;
      }
      span {
        margin-left: 10px;
        svg {
          font-size: 15px;
        }
      }
    }
    .team_table {
    font-size: 12px;
    border-radius: 8px 8px 8px 8px !important;
  }

  tr:last-child {
    border-bottom: none !important;
  }

  table:hover {
    cursor: pointer;
  }
  .dropdrop::-webkit-scrollbar {
  display: none;

  }
  </style>
